import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';

import ImageMeta from './ImageMeta';
import getAuthorProperties from './getAuthorProperties';
import config from '../../../utils/siteConfig';
import { GhostSettingsDataProps } from '../Layout';
import { IWebsiteMetaProps } from './WebsiteMeta';

const GHOST_SETTINGS_QUERY = graphql`
  query AuthorMetaData {
    ghostSettings {
      ...GhostSettingsFields
    }
  }
`;

const AuthorMeta = ({ data, canonical }: IWebsiteMetaProps) => {
  const settingsQueryData: GhostSettingsDataProps = useStaticQuery(
    GHOST_SETTINGS_QUERY,
  );
  const ghostSettings = settingsQueryData.ghostSettings;

  const author = getAuthorProperties(data);
  const shareImage = author.image || _.get(ghostSettings, `cover_image`, null);
  const title = `${data.name} - ${ghostSettings.title}`;
  const description =
    data.bio || config.siteDescriptionMeta || ghostSettings.description;

  const jsonLd = {
    '@context': `https://schema.org/`,
    '@type': `Person`,
    name: data.name,
    sameAs: author.sameAsArray ? author.sameAsArray : undefined,
    url: canonical,
    image: shareImage
      ? {
          '@type': `ImageObject`,
          url: shareImage,
          width: config.shareImageWidth,
          height: config.shareImageHeight,
        }
      : undefined,
    mainEntityOfPage: {
      '@type': `WebPage`,
      '@id': config.siteUrl,
    },
    description,
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={ghostSettings.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {ghostSettings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${ghostSettings.twitter.replace(
              /^@/,
              ``,
            )}/`}
          />
        )}
        {ghostSettings.twitter && (
          <meta name="twitter:creator" content={ghostSettings.twitter} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};
export default AuthorMeta;
