import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import url from 'url';

import ImageMeta from './ImageMeta';
import config from '../../../utils/siteConfig';
import { GhostSettingsDataProps } from '../Layout';

export const GHOST_SETTINGS_QUERY = graphql`
  query Settings {
    ghostSettings {
      ...GhostSettingsFields
    }
  }
`;
export interface IPageProps {
  title: string;
  meta_title: string;
  meta_description: string;
  name: string;
  feature_image: string;
  description: string;
  bio: string;
  profile_image: string;
}

export interface IWebsiteMetaProps {
  // TODO revisit how metaData is handled
  data: any;
  location?: {
    pathname: string;
  };
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  canonical?: string;
}

const WebsiteMeta = ({
  data,
  canonical,
  title,
  description,
  image,
  type,
}: IWebsiteMetaProps) => {
  const settingsQueryData: GhostSettingsDataProps = useStaticQuery(
    GHOST_SETTINGS_QUERY,
  );
  const ghostSettings = settingsQueryData.ghostSettings;

  const publisherLogo = url.resolve(
    config.siteUrl,
    ghostSettings.logo || config.siteIcon,
  );
  let shareImage =
    image || data.feature_image || _.get(ghostSettings, `cover_image`, null);

  shareImage = shareImage ? url.resolve(config.siteUrl, shareImage) : null;

  description =
    description ||
    data.meta_description ||
    data.description ||
    config.siteDescriptionMeta ||
    ghostSettings.description;
  title = `${title || data.meta_title || data.name || data.title} - ${
    ghostSettings.title
  }`;

  const jsonLd = {
    '@context': `https://schema.org/`,
    '@type': type,
    url: canonical,
    image: shareImage
      ? {
          '@type': `ImageObject`,
          url: shareImage,
          width: config.shareImageWidth,
          height: config.shareImageHeight,
        }
      : undefined,
    publisher: {
      '@type': `Organization`,
      name: ghostSettings.title,
      logo: {
        '@type': `ImageObject`,
        url: publisherLogo,
        width: 60,
        height: 60,
      },
    },
    mainEntityOfPage: {
      '@type': `WebPage`,
      '@id': config.siteUrl,
    },
    description,
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={ghostSettings.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {ghostSettings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${ghostSettings.twitter.replace(
              /^@/,
              ``,
            )}/`}
          />
        )}
        {ghostSettings.twitter && (
          <meta name="twitter:creator" content={ghostSettings.twitter} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};
export default WebsiteMeta;
