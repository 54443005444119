import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import url from 'url';

import config from '../../../utils/siteConfig';
import ArticleMeta from './ArticleMeta';
import WebsiteMeta from './WebsiteMeta';
import AuthorMeta from './AuthorMeta';
import { GhostSettingsDataProps } from '../Layout';
import { IPostProps } from '../../../templates/post';
import { IAuthorProps } from '../../../templates/author';
import { ITagProps } from '../../../templates/tag';

const METADATA_QUERY = graphql`
  query MetaData {
    ghostSettings {
      title
      description
      cover_image
    }
  }
`;

export interface IMetaDataProps {
  data?: {
    ghostPost?: IPostProps;
    ghostTag?: ITagProps;
    ghostAuthor?: IAuthorProps;
    ghostPage?: {};
  };
  location: {
    pathname: string;
  };
  title?: string;
  description?: string;
  image?: string;
}

const MetaData = ({
  data = {},
  title,
  description,
  image,
  location,
}: IMetaDataProps) => {
  const settingsQueryData: GhostSettingsDataProps = useStaticQuery(
    METADATA_QUERY,
  );
  const ghostSettings = settingsQueryData.ghostSettings;
  const canonical = url.resolve(config.siteUrl, location.pathname);
  const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data;

  if (ghostPost) {
    return <ArticleMeta data={ghostPost} canonical={canonical} />;
  } else if (ghostTag) {
    return <WebsiteMeta data={ghostTag} canonical={canonical} type="Series" />;
  } else if (ghostAuthor) {
    return <AuthorMeta data={ghostAuthor} canonical={canonical} />;
  } else if (ghostPage) {
    return (
      <WebsiteMeta data={ghostPage} canonical={canonical} type="WebSite" />
    );
  } else {
    title = title || config.siteTitleMeta || ghostSettings.title;
    description =
      description || config.siteDescriptionMeta || ghostSettings.description;
    image = image || ghostSettings.cover_image || '';

    image = image ? url.resolve(config.siteUrl, image) : '';

    return (
      <WebsiteMeta
        data={{}}
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        type="WebSite"
      />
    );
  }
};

export default MetaData;
