module.exports = {
  siteUrl: `https://blog.yozobi.com`, // Site domain. Do not include a trailing slash!

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Yozobi Blog`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `Articles by the team at Yozobi`, // This allows an alternative site description for meta data for pages.

  shareImageWidth: '1000', // Change to the width of your default share image
  shareImageHeight: '523', // Change to the height of your default share image

  shortTitle: `Yozobi Blog`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#2d3555`, // Used for Offline Manifest
  themeColor: `#03d8b9`, // Used for Offline Manifest
};
